import React from 'react'
import { NavBarComponent } from '../components/layout/NavBarComponent'
import { Outlet } from 'react-router-dom'
import { FooterComponent } from '../components/layout/FooterComponent'

const Layout = () => {
  return (
    < >
    <NavBarComponent/>
    <Outlet/>
    <FooterComponent/>
    
    </>
  )
}

export default Layout