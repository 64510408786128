// store.js
import { configureStore } from '@reduxjs/toolkit';
import { productSlice, productDetailSlice } from './productSlice'; // Replace with the actual paths
import aboutReducer from './aboutSlice';
import categoriesReducer from './categoriesSlice';


const store = configureStore({
  reducer: {
    products: productSlice.reducer,
    productDetail: productDetailSlice.reducer,
    about:aboutReducer,
    categories: categoriesReducer
  },
});

export default store;
