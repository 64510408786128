import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProducts } from '../../store/productSlice';
import { Link } from 'react-router-dom';

export const NewProductsComponent = () => {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.products);
    const status = useSelector((state) => state.products.status);
    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchAllProducts());
        }
    }, [status, dispatch]);

    if (status === 'loading') {
        return <div>Loading...</div>;
    } else if (status === 'failed') {
        return <div>Error: Unable to fetch products</div>;
    }
   // console.log(products)   
    // function isProductNew(createdAt) {
    //     // Compare the createdAt date with your criteria (e.g., products created within the last 30 days are considered new)
    //     const cutoffDate = new Date();
    //     cutoffDate.setDate(cutoffDate.getDate() - 30); // Change 30 to the desired number of days
    //     return new Date(createdAt) >= cutoffDate;
    // }

  return (
      <div className="popular-product" style={{ backgroundColor:"#f3e8e1" }}>
          <div className="container">
              <div className="row ">
                  <h1 className='text-center pt-3 pb-5'>Our Latest Products</h1>

                  {
                      products &&
                      [...products] // Create a shallow copy of the 'products' array
                          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                          .slice(0, 6)
                          .map((product, idx) => (
                              <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0 pb-3 con-zoom position-relative border" key={idx}>
                                  <Link className="text-decoration-none" to={`/product-detail/${product.id}`}>
                                  <div  className="position-absolute top-0 end-0 m-1">
                                      <p  className="bg-danger text-white rounded-circle p-1">
                                          New
                                      </p>
                                  </div>
                                
                                  <div className="product-item-sm d-flex zom-in">
                                      <div className="thumbnail">
                                          <img src={product.product_image} alt="Image" className="img-fluid" />
                                      </div>
                                      <div className="pt-3">
                                          <h3>{product.title}</h3>
                                          <p>{`${product.description.substring(0, 60)}......`}</p>
                                          <p className='text-success'>Read More</p>
                                      </div>
                                  </div>

</Link>
                              </div>
                          ))
                  }
              </div>
          </div>
      </div>
  )
}
