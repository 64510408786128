import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProducts } from '../../store/productSlice';
import { Link } from 'react-router-dom';

export const ProductComponent = () => {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.products);
    const status = useSelector((state) => state.products.status);

    useEffect(() => {
        if (status === 'idle') {
          dispatch(fetchAllProducts());
        }
      }, [status, dispatch]);
    
      if (status === 'loading') {
        return <div>Loading...</div>;
      } else if (status === 'failed') {
        return <div>Error: Unable to fetch products</div>;
      }
//console.log(products)   
    function shuffleArray(array) {
        const newArray = [...array];
        for (let i = newArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
        }
        return newArray;
    }

  return (
    <div className="product-section p-0">
    <div className="container">
        <div className="row">


            <div className="col-md-12 col-lg-3 mb-5 mb-lg-0">
                <h2 className="mb-4 section-title">Crafted with excellent material.</h2>
                <p className="mb-4">Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique. </p>
                <p><a href="shop.html" className="btn">Explore</a></p>
            </div> 
          
                  {
                      products &&
                      shuffleArray(products).slice(0, 7).map((product, idx) => (
                          <div className="col-12 col-md-4 col-lg-3 mb-5" key={idx}>
                              <Link className="product-item" to={`/product-detail/${product.id}`}>
                                  <img src={product.product_image} className="img-fluid product-thumbnail" />
                                  <h3 className="product-title">{product.title}</h3>
                                  <strong className="product-price">Rs.{product.price}</strong>
                                  <span className="icon-cross">
                                      <img src="assets/images/cross.svg" className="img-fluid" />
                                  </span>
                              </Link>
                          </div>
                      ))
                  }

            {/* <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
                <a className="product-item" href="cart.html">
                    <img src="assets/images/product-1.png" className="img-fluid product-thumbnail"/>
                    <h3 className="product-title">Nordic Chair</h3>
                    <strong className="product-price">$50.00</strong>

                    <span className="icon-cross">
                        <img src="assets/images/cross.svg" className="img-fluid"/>
                    </span>
                </a>
            </div>  */}
      
            {/* <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
                <a className="product-item" href="cart.html">
                    <img src="assets/images/product-2.png" className="img-fluid product-thumbnail"/>
                    <h3 className="product-title">Kruzo Aero Chair</h3>
                    <strong className="product-price">$78.00</strong>

                    <span className="icon-cross">
                        <img src="assets/images/cross.svg" className="img-fluid"/>
                    </span>
                </a>
            </div>
   

       
            <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
                <a className="product-item" href="cart.html">
                    <img src="assets/images/product-3.png" className="img-fluid product-thumbnail"/>
                    <h3 className="product-title">Ergonomic Chair</h3>
                    <strong className="product-price">$43.00</strong>

                    <span className="icon-cross">
                        <img src="assets/images/cross.svg" className="img-fluid"/>
                    </span>
                </a>
            </div> */}
       

        </div>
    </div>
</div>
  )
}
