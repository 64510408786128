import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCategories } from '../../store/categoriesSlice';
import { IMG_URL } from '../../store/config';
import { Link } from 'react-router-dom';

export const CategoryComponent = () => {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.categories.categories);
    const status = useSelector((state) => state.categories.status);
    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchAllCategories());
        }
    }, [status, dispatch]);

    if (status === 'loading') {
        return <div>Loading...</div>;
    } else if (status === 'failed') {
        return <div>Error: Unable to fetch products</div>;
    }
    console.log(categories)
    return (
        <>
            <div className="popular-product pt-5">
                <div className="container">
                    <h1 className='text-center pt-3 pb-5'>Shop By Category</h1>
                    <div className="row">


                        {
                            categories && categories.map((cate, idx) => (
                                <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0 pb-3 shadow-sm con-zoom" key={idx}>
                                    <Link to={`/products?categoryId=${cate.id}`} className='text-decoration-none'>
                                    <div className="product-item-sm d-flex zom-in ">
                                        {
                                            cate.image &&
                                            <div className="thumbnail mt-2">
                                                <img src={`${IMG_URL}${cate.image}`} alt="Image" className="img-fluid" />
                                            </div>
                                        }

                                        <div className="pt-3">

                                            <h3>{cate.title ? cate.title : ""}</h3>
                                            {/* <p>Donec facilisis quam ut purus rutrum lobortis. Donec vitae odio </p> */}
                                            <p>View More</p>
                                        </div>
                                    </div>
                                    </Link>
                                </div>

                            ))
                        }

                    </div>
                </div>
            </div>

        </>
    )
}
