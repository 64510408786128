import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAllProducts } from '../../store/productSlice';

export const PopularProductComponent = () => {
	const dispatch = useDispatch();
	const products = useSelector((state) => state.products.products);
	const status = useSelector((state) => state.products.status);
	useEffect(() => {
		if (status === 'idle') {
			dispatch(fetchAllProducts());
		}
	}, [status, dispatch]);

	if (status === 'loading') {
		return <div>Loading...</div>;
	} else if (status === 'failed') {
		return <div>Error: Unable to fetch products</div>;
	}
	// console.log(products)  

	function shuffleArray(array) {
		const newArray = [...array];
		for (let i = newArray.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[newArray[i], newArray[j]] = [newArray[j], newArray[i]];
		}
		return newArray;
	}

	return (
		<div className="popular-product">
			<div className="container">
				<div className="row">
					<h1 className='text-center pt-3 pb-5'>Our Feature Procucts</h1>

					{
						products &&
						shuffleArray(products)
							.filter((product) => product.isFeatured)
							.slice(0, 6)
							.map((product, idx) => (

								<div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0 pb-3 con-zoom" key={idx}>
									<Link className="text-decoration-none" to={`/product-detail/${product.id}`}>
									<div className="product-item-sm d-flex zom-in">
										<div className="thumbnail">
											<img src={product.product_image} alt="Image" className="img-fluid" />
										</div>
										<div className="pt-3">
											<h3>{product.title}</h3>
											<p>{`${product.description.substring(0, 60)}......`}</p>
												<p className='text-success'>
												Read More
												
												</p>
										</div>
									</div>
									</Link>
								</div>

							))
					}
				</div>
			</div>
		</div>
	)
}
