import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from './config'
import axios from 'axios';

// Define an initial state
const initialState = {
    categories: [],
    status: 'idle',
    error: null,
};

// Define an async thunk to fetch all categories
const fetchAllCategories = createAsyncThunk('menu/fetchAllCategories', async () => {
    const response = await axios.get(`${API_URL}menu `); // Replace with your API endpoint
    // console.log("ff", response.data.categories)
    return response.data.categories;
});

// Create a slice
const cotagorySlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAllCategories.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categories = action.payload;
            })
            .addCase(fetchAllCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default cotagorySlice.reducer;

// Export the async thunk for use in components
export { fetchAllCategories };