import { Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import HomePage from "./pages/HomePage";
import ProductsPage from "./pages/ProductsPage";
import AboutUsPage from "./pages/AboutUsPage";
import ServicesPage from "./pages/ServicesPage";
import Contactpage from "./pages/Contactpage";
import ProductDetailPage from "./pages/ProductDetailPage";


function App() {
  return (
    <>


<Routes>
        <Route path="/" element={<Layout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/contact" element={<Contactpage />} />
          <Route path="/product-detail/:productId"element={<ProductDetailPage/>}/>


              
        </Route>
      </Routes>
    </>
  );
}

export default App;
