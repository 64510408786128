import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {API_URL} from './config'
import axios from 'axios';

// Define an initial state
const initialState = {
    products: [],
    status: 'idle',
    error: null,
  productDetail: null,
  };

  // Define an async thunk to fetch all products
export const fetchAllProducts = createAsyncThunk('products/fetchAllProducts', async () => {
    const response = await axios.get(`${API_URL}all-products `); // Replace with your API endpoint
    // console.log(response.data)
    return response.data.products;
  });

// Define an async thunk action for fetching product detail
export const fetchProductDetail = createAsyncThunk(
  'product/fetchProductDetail', async (productId) => {
    console.log("I am proDetail")
    try {
      
      const response = await axios.get(`${API_URL}product-detail/${productId}`);
      //sconsole.log("ddd",response.data.product)
      return response.data.product[0];
    } catch (error) {
      throw error;
    }
  }
);


  // Create a slice
export const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchAllProducts.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchAllProducts.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.products = action.payload;
        })
        .addCase(fetchAllProducts.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });
  




// Create a productDetailSlice
export const productDetailSlice = createSlice({
  name: 'productDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductDetail.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchProductDetail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.productDetail = action.payload;
      })
      .addCase(fetchProductDetail.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Export the reducer and actions for products and product details
export const productActions = productSlice.actions;
export const productDetailActions = productDetailSlice.actions;

export default productSlice.reducer;

  
