import React, { useEffect, useState } from 'react'
import { HeroComponent } from '../components/layout/HeroComponent'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProducts } from '../store/productSlice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchAllCategories } from '../store/categoriesSlice';

const ProductsPage = () => {
	const dispatch = useDispatch();
	const products = useSelector((state) => state.products.products);
	const status = useSelector((state) => state.products.status);

	const categories = useSelector((state) => state.categories.categories);
	const cat_status = useSelector((state) => state.categories.status);

	const navigate = useNavigate();
	const [selectedCategory, setSelectedCategory] = useState(null);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	let cateId = searchParams.get('categoryId');



	useEffect(() => {
		// console.log("merger", mergedCategory)
		if (status === 'idle') {
			dispatch(fetchAllProducts());
		}
	}, [status, dispatch]);

	useEffect(() => {
		if (cat_status === 'idle') {
			dispatch(fetchAllCategories());
		}
	}, [cat_status, dispatch]);

	if (status === 'loading') {
		return <div>Loading...</div>;
	} else if (status === 'failed') {
		return <div>Error: Unable to fetch products</div>;
	}






	if (cat_status === 'loading') {
		return <div>Loading...</div>;
	} else if (cat_status === 'failed') {
		return <div>Error: Unable to fetch categories</div>;
	}

	const handleCategoryClick = (categoryId) => {
		cateId = null;
		setSelectedCategory(categoryId);
		navigate('/products');
	};


	let filteredProducts;

	if (cateId) {
		// Filter products based on 'categoryId' from query parameters
		console.log("categoryId", cateId)
		const catId = Number(cateId)
		filteredProducts = products.filter((product) => product.category_id === catId);
	} else if (selectedCategory) {
		// Filter products based on 'selectedCategory' from state
		console.log("seleted")
		filteredProducts = products.filter((product) => product.category_id === selectedCategory);
		console.log(filteredProducts)
	} else {
		// If neither 'categoryId' nor 'selectedCategory' is present, show all products
		filteredProducts = products;
	}

	return (
		<>
			<HeroComponent title="Check our Products" />
			<div className="untree_co-section product-section before-footer-section">
				<div className="container-fluid">
					<div className="row">
						<div className='col-md-3'>
							



							<div className='sticky-md-top'>

								<div className="d-flex flex-column flex-shrink-0 p-3 bg-light" >

								
								
										<h5 className='text-center'>Categories</h5>
								
								
									<ul className="nav nav-pills flex-column mb-auto">
									
										<li className="nav-item nav-link active mb-2" onClick={() => handleCategoryClick(null)} role="button" style={{ backgroundColor:"#5a3220" }}>All
											
										</li>


											{
									categories && categories.map((category, idx) => (

										<li
											key={idx}
											className={`nav-item nav-link  ${selectedCategory === category.id ? 'active' : ''
												}`}
											
											onClick={() => handleCategoryClick(category.id)}
											role='button'
										
										>
											{category.title}
										</li>
									))
								}
									
										</ul>
								</div>

							

							
					
							</div>


						</div>
						<div className='col-md-9'>
							<div className='row'>

								{
									products && filteredProducts &&
									filteredProducts.map((product, idx) => (
										<div className="col-12 col-md-4 col-lg-3 mb-5" key={idx}>
											<Link className="product-item" to={`/product-detail/${product.id}`}>
												<img src={product.product_image} className="img-fluid product-thumbnail" />
												<h3 className="product-title">{product.title}</h3>
												<strong className="product-price">Rs.{product.price}</strong>

												<span className="icon-cross">
													<img src="assets/images/cross.svg" className="img-fluid" />
												</span>
											</Link>
										</div>

									))

								}
							</div>


						</div>




						{/* <div className="col-12 col-md-4 col-lg-3 mb-5">
						<a className="product-item" href="#">
							<img src="assets/images/product-1.png" className="img-fluid product-thumbnail"/>
							<h3 className="product-title">Nordic Chair</h3>
							<strong className="product-price">$50.00</strong>

							<span className="icon-cross">
								<img src="assets/images/cross.svg" className="img-fluid"/>
							</span>
						</a>
					</div> 
		
					<div className="col-12 col-md-4 col-lg-3 mb-5">
						<a className="product-item" href="#">
							<img src="assets/images/product-2.png" className="img-fluid product-thumbnail"/>
							<h3 className="product-title">Kruzo Aero Chair</h3>
							<strong className="product-price">$78.00</strong>

							<span className="icon-cross">
								<img src="assets/images/cross.svg" className="img-fluid"/>
							</span>
						</a>
					</div>
	
					<div className="col-12 col-md-4 col-lg-3 mb-5">
						<a className="product-item" href="#">
							<img src="assets/images/product-3.png" className="img-fluid product-thumbnail"/>
							<h3 className="product-title">Ergonomic Chair</h3>
							<strong className="product-price">$43.00</strong>

							<span className="icon-cross">
								<img src="assets/images/cross.svg" className="img-fluid"/>
							</span>
						</a>
					</div>
	
					<div className="col-12 col-md-4 col-lg-3 mb-5">
						<a className="product-item" href="#">
							<img src="assets/images/product-3.png" className="img-fluid product-thumbnail"/>
							<h3 className="product-title">Nordic Chair</h3>
							<strong className="product-price">$50.00</strong>

							<span className="icon-cross">
								<img src="assets/images/cross.svg" className="img-fluid"/>
							</span>
						</a>
					</div> 
					
					<div className="col-12 col-md-4 col-lg-3 mb-5">
						<a className="product-item" href="#">
							<img src="assets/images/product-1.png" className="img-fluid product-thumbnail"/>
							<h3 className="product-title">Nordic Chair</h3>
							<strong className="product-price">$50.00</strong>

							<span className="icon-cross">
								<img src="assets/images/cross.svg" className="img-fluid"/>
							</span>
						</a>
					</div> 
				
					<div className="col-12 col-md-4 col-lg-3 mb-5">
						<a className="product-item" href="#">
							<img src="assets/images/product-2.png" className="img-fluid product-thumbnail"/>
							<h3 className="product-title">Kruzo Aero Chair</h3>
							<strong className="product-price">$78.00</strong>

							<span className="icon-cross">
								<img src="assets/images/cross.svg" className="img-fluid"/>
							</span>
						</a>
					</div>
			
					<div className="col-12 col-md-4 col-lg-3 mb-5">
						<a className="product-item" href="#">
							<img src="assets/images/product-3.png" className="img-fluid product-thumbnail"/>
							<h3 className="product-title">Ergonomic Chair</h3>
							<strong className="product-price">$43.00</strong>

							<span className="icon-cross">
								<img src="assets/images/cross.svg" className="img-fluid"/>
							</span>
						</a>
					</div> */}


					</div>
				</div>
			</div>

		</>
	)
}

export default ProductsPage