import React from 'react'
import { HeroComponent } from '../components/layout/HeroComponent'
import { ProductComponent } from '../components/HomeComponent/ProductComponent'
import { WeHelpComponent } from '../components/HomeComponent/WeHelpComponent'
import { PopularProductComponent } from '../components/HomeComponent/PopularProductComponent'
import { CategoryComponent } from '../components/HomeComponent/CategoryComponent'
import { NewProductsComponent } from '../components/HomeComponent/NewProductsComponent'

const HomePage = () => {
  return (
    < >
  
      <HeroComponent title="Crafting Comfort, From Our Hands to Your Home"/>
    <CategoryComponent/>
    <ProductComponent/>
    <WeHelpComponent/>
    <PopularProductComponent/>
    <NewProductsComponent/>
    
    </>
  )
}

export default HomePage