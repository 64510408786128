import React, { useEffect } from 'react'
import './ProductDetailPage.css'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchProductDetail } from '../store/productSlice';

const ProductDetailPage = () => {
    const dispatch = useDispatch();
    const { productId } = useParams(); // Access productId from the URL
    const product = useSelector((state) => state.productDetail.productDetail);
    const status = useSelector((state) => state.productDetail.status);
    const error = useSelector((state) => state.productDetail.error);

    //   useEffect(()=>{
    //     console.log("hello another")
    //   })

    //     console.log("useEffect above", productId)

    useEffect(() => {
        console.log("useEffect", productId)

        dispatch(fetchProductDetail(productId));

    }, [dispatch, productId]);


    if (status === 'loading') {
        return <div>Loading...</div>;
    } else if (status === 'failed') {
        return <div>Error: Unable to fetch products</div>;
    }

    // console.log("pro", product)

    return (
        <div>
            {
                product &&
                <div className="container-fluid mt-5 mb-5">

                    <div className="card">

                        <div className="row g-0">

                            <div className="col-md-6 border-end">

                                <div className="d-flex flex-column justify-content-center">


                                    <div className="main_image">
                                        {
                                            product && product.product_image &&
                                            <img
                                                src={product.product_image}
                                                id="main_product_image"
                                                alt='Product Image'
                                                width="350"
                                            />

                                        }

                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">

                                <div className="p-3 right-side">

                                    <div className="d-flex justify-content-between align-items-center">

                                        <h3>{product.title ? product.title : ""}</h3>
                                        <span className="heart">
                                            <i className="bx bx-heart"></i>
                                        </span>
                                    </div>
                                    <div className="mt-2 pr-3 content">
                                        {
                                            product && product.description &&
                                            <div>{product.description}</div>
                                        }


                                    </div>
                                    {
                                        product && product.items &&

                                        <>
                                            <h4 className='text-center mt-4'>Specifications</h4>

                                            <div dangerouslySetInnerHTML={{ __html: product.items }} />
                                        </>

                                    }

                                    <h3>{product.price ? `Rs ${product.price}` : ""}</h3>
                                    {/* <div  className="ratings d-flex flex-row align-items-center">

                                    <div  className="d-flex flex-row">

                                        <i  className="bx bxs-star"></i> <i  className="bx bxs-star"></i> 
                                        <i  className="bx bxs-star"></i> <i  className="bx bxs-star"></i> 
                                        <i  className="bx bx-star"></i> 
                                    </div> 
                                    <span>441 reviews</span> 
                                </div>  */}
                                    {/* <div  className="mt-5">

                                    <span  className="fw-bold">Color</span> 
                                    <div  className="colors">

                                        <ul id="marker">

                                            <li id="marker-1"></li> <li id="marker-2"></li> 
                                            <li id="marker-3"></li> <li id="marker-4"></li> 
                                            <li id="marker-5"></li> 
                                        </ul> 
                                    </div> 
                                </div>  */}
                                    <div className='row mb-5'>
                                        <div className='col-md-6' >
                                            <div className="input-group">
                                                <button className="input-group-text btn btn-primary" id="btnGroupAddon">-</button>
                                                <input type="number" className="form-control" placeholder="quantity" aria-label="Input group example" aria-describedby="btnGroupAddon" />
                                                <button className="input-group-text btn btn-primary" id="btnGroupAddon+">+</button>

                                            </div>
                                        </div>
                                        <div className='col-md-6 mt-3 mt-md-0'>
                                            <button className='btn btn-primary'>Add to cart</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }



        </div>
    )
}

export default ProductDetailPage