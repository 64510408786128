import React from 'react'
import { Link } from 'react-router-dom'

export const HeroComponent = ({ title }) => {
    return (
        <div className="hero">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-5">
                        <div className="intro-excerpt">
                            <h1>{title}</h1>
                            <p className="mb-4">Our furniture manufacturing expertise brings your dream designs to life, while our showroom showcases the finest craftsmanship for your ideal living space.</p>
                            <p><Link to="/products" className="btn btn-secondary me-2">Shop Now</Link></p>
                        </div>
                    </div>
                    <div className="col-lg-7 d-none d-md-block d-lg-block">
                        <div className="hero-img-wrap">
                            <img src="assets/images/couch.png" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
