import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from './config'
import axios from 'axios';

// Define an initial state
const initialState = {
    about: {},
    status: 'idle',
    error: null,
};

// Define an async thunk to fetch all products
const fetchAbout = createAsyncThunk('aboutpage/fetchAbout', async () => {
    // console.log("sadsadfas");
    const response = await axios.get(`${API_URL}about-us`); // Replace with your API endpoint
    //  console.log(response.data)
    return response.data.cmsPageDetails[0];
});

// Create a slice
const aboutSlice = createSlice({
    name: 'about',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAbout.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAbout.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.about = action.payload;
            })
            .addCase(fetchAbout.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default aboutSlice.reducer;

// Export the async thunk for use in components
export { fetchAbout };