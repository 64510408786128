import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

export const NavBarComponent = () => {
  const location = useLocation();
  return (
    <nav className="custom-navbar navbar navbar-expand-md navbar-dark bg-dark" aria-label="Furni navigation bar">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src='./logo.png' alt='logo' width="110"/>
         
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsFurni"
          aria-controls="navbarsFurni"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarsFurni">
          <ul className="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
            <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
              <Link className='nav-link' to="/">
                Home
              </Link>
            </li>
            <li className={`nav-item ${location.pathname === '/products' ? 'active' : ''}`}>
              <Link className="nav-link" to="/products">
                Products
              </Link>
            </li>
            <li className={`nav-item ${location.pathname === '/about' ? 'active' : ''}`}>
              <Link className="nav-link" to="/about">
                About us
              </Link>
            </li>
            <li className={`nav-item ${location.pathname === '/services' ? 'active' : ''}`}>
              <Link className="nav-link" to="/services">
                Services
              </Link>
            </li>
       
            <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
              <Link className="nav-link" to="/contact">
                Contact us
              </Link>
            </li>
          </ul>

          <ul className="custom-navbar-cta navbar-nav mb-2 mb-md-0 ms-5">
            <li>
              <a className="nav-link" href="#">
                <img src="/assets/images/user.svg" alt="User" />
              </a>
            </li>
            <li>
              <a className="nav-link" href="#">
                <img src="/assets/images/cart.svg" alt="Cart" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
